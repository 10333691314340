<template>
  <div class="container block">
    <h1 class="xl-title">Legals</h1>
    <p class="text">
      COPYRIGHT 2021 SARL HAUTES CÔTES <br />
      SIREN: 901650606 <br />
      Capital: 8,000€ <br />
      TVA: FR33901650606 <br />
      (+33) 07 83 53 60 30 <br />
      info@hautescotes.com <br />
    </p>
    <p class="text">
      Photos courtesy of: Benoit Guenot, Quantum Film Productions, Oliver
      Pilcher, Bourse de Commerce - Pinault Collection Photo: Maxime Têtard /
      Studio Les Graphiquants - Paris, Eric Sander for Domaine de Chaumont sur
      Loire, Thibault Bevilacqua, Romain Laprade, Baptiste Buisson, Claudio
      Poggio
    </p>
    <p class="text">
      Supporting Branding & Design Agency:
      <a
        href="http://apassionthing.com/"
        target="_blank"
        title="A PASSION THING"
        >A PASSION THING</a
      >
    </p>
    <h2 class="s-title">TERMS & CONDITIONS</h2>
    <p class="text">
      Thank you for visiting this page! We value your business and look forward
      to hosting you as our guest. This page is designed to make you fully aware
      of the terms, conditions and policies governing your participation on our
      trips.
    </p>
    <p class="text">
      The terms “Hautes Côtes,” “we” and “our” refer to SARL Hautes Côtes, a
      French corporation which operates in Burgundy, France, SIRET number 901
      650 606 00014. All trips are offered subject to Hautes Cotes standard
      terms and conditions as outlined below. Please review the terms and
      conditions carefully prior to completing your reservation. Some of these
      terms and conditions are set out below to draw your attention to the fact
      that our obligations to you are limited. Should you have any questions or
      concerns please contact us via info@hautescotes.com
    </p>
    <h2 class="s-title">PRICES, RESERVATIONS AND PAYMENT</h2>
    <p class="text">
      To reserve your Hautes Côtes trip, we first need to get in touch through
      info@hautescotes.com to get to know each other, understand who you are,
      your values and your dream experience. We will schedule time to speak, and
      do what we do best: take time to listen and discuss, so we know how to
      curate a program completely in line with your desires.
    </p>
    <p class="text">
      Should we then agree on designing a unique trip together, we will get to
      work and reach out to our network, to start sketching the “outline” of
      your program. We will come back to you with an initial trip proposal and
      will continue the conversation. At this stage, we will ask for a deposit
      to confirm your space.
    </p>
    <p class="text">
      We will keep working until we arrive at a completely personalized
      itinerary that we are both excited about. The balance of your payment is
      due at least 60 days prior to departure. Should there be any exception to
      this timeline due to the terms of third parties, this will be outlined in
      your contract.
    </p>
    <p class="text">
      To complete your reservation, you must have paid the deposit and signed
      the contract. Before your trip begins, we require a Traveller Information
      Form be filled out.
    </p>
    <h2 class="s-title">PREPARING FOR YOUR TRIP</h2>
    <p class="text">
      We do not require that you take out travel insurance, but please be aware
      that we strongly recommend that you do so. While we sincerely hope they
      will not occur, unexpected issues such as pre-trip accidents or personal
      conflicts do come up, and travel insurance is your best option. We
      recommend that you first check with your personal and health insurance
      providers to know your level of coverage, as well as coverage of lost or
      damaged items.
    </p>
    <h2 class="s-title">TRAVEL DOCUMENTS AND PASSPORTS</h2>
    <p class="text">
      We will do our best to share with you the latest information regarding
      travel requirements, but we would like to outline that it is the
      responsibility of the traveler that she/he has the appropriate documents
      to visit the country of the trip. Hautes Côtes is not liable for any
      expenses or costs arising from any traveler being prohibited from entering
      a country scheduled to be visited on a trip.
    </p>
    <h2 class="s-title">HEALTH</h2>
    <p class="text">
      We would like to outline that in some countries local living standards,
      practices, services and accommodations may differ from those you are
      familiar with. We count on our travelers to ensure they are healthy and
      fit if the designed trip requires physical activity.
    </p>
    <p class="text">
      By sending a deposit toward your trip purchase and completing your
      Traveller Information Form, you certify that you do not have any physical
      or mental condition or disability that would create a hazard for you or
      other travellers and that you have received all appropriate or necessary
      vaccinations indicated by your proposed trip. We recommend that you
      consult your doctor prior to the trip.
    </p>
    <h2 class="s-title">DURING THE TRIP</h2>
    <p class="text">
      We reserve the right to modify any trip and its associated activities,
      including where modifications are necessary or advisable (as determined by
      us)
    </p>
    <ol class="text">
      <li>
        to accommodate any physical, mental or emotional limitation, condition,
        limitation or ability of which you have advised us;
      </li>
      <li>
        due to health and safety concerns arising from local conditions
        including those related to the COVID-19 global pandemic and your
        vaccination status. You acknowledge that should you choose not to advise
        us of any physical, mental or emotional condition, limitation or
        ability, or vaccination status, we may not be able to fully accommodate
        you on all aspects and features of a trip.
      </li>
      <li>to adapt to any local unforeseeable event.</li>
    </ol>
    <p class="text">
      Be assured that we will do everything we can to be as close as possible to
      the itinerary and activities we have been discussing, but Hautes Côtes
      cannot be held responsible for events out of its control.
    </p>

    <h2 class="s-title">TRAVEL WAIVER</h2>
    <p class="text">
      Joining a trip is an exceptional opportunity, but it is not without
      certain risks, dangers, hazards and liabilities to all participants. All
      persons taking part in any Hautes Côtes trip are required to accept these
      and other risks as a condition of their participation in this program.
    </p>

    <h3 class="xs-title">Risks</h3>
    <p class="text">
      Travel involves risks. Our trip often veers off the beaten path. Our trips
      may be physically demanding and there is a risk of serious personal
      injury, including permanent disability and death from accident, illness or
      the actions of negligence of other persons.
    </p>

    <h3 class="xs-title">Limitation of Liability, Release and Indemnity</h3>
    <p class="text">
      Travelers on this trip must accept the risks of travel and assume full
      responsibility for any loss, injury, death or damage to them, their family
      or their dependants or property arising in connection with their
      participation, or the participation of their family, in the trip.
    </p>
    <p class="text">
      Travelers must have their own insurance appropriate to their needs and the
      needs of their family or dependants in respect of such loss, injury, death
      or damage.
    </p>
    <p class="text">
      As Hautes Côtes is acting as an agent of you with respect to all
      transportation, accommodations, meals and other arrangements, in
      consideration for participating in the trips, travelers agree not to sue
      Hautes Côtes, its employees, directors, and assigns in respect of, and
      forever release them from, all liability for economic loss and physical or
      mental injury, direct or indirect, arising in connection with the trip and
      the actions of any third party service providers—including, without
      limitation, permanent disability and death; any emotional distress; any
      loss of services, financial support, aid, consortium or companionship; and
      any damage to or loss of property—in each case even if caused in whole or
      in part by the conduct, including the negligence, of Hautes Côtes, except
      where such loss, injury or damage is caused by fraudulent conduct on the
      part of Hautes Côtes or their employees, guides, or directors, in which
      case the Traveler retains the right to sue only Hautes Côtes in respect of
      that conduct.
    </p>
    <p class="text">
      Travelers further agree that they will indemnify and hold Hautes Côtes
      harmless in respect of any claims arising in connection with the trip made
      by:
    </p>
    <ol class="text">
      <li>third parties, which arise from the misconduct of the travelers;</li>
      <li>the traveler's family members;</li>
      <li>
        third parties, which the traveler, their estate, their family members,
        dependants or heirs
      </li>
    </ol>
    <p class="text">
      have sued, if damages are recovered from such third parties, to the extent
      the third party obtains any indemnification from Hautes Côtes; except
      where the loss, injury, death or damage is caused by the reckless or
      fraudulent conduct on the part of Hautes Côtes, or their employees,
      officers or directors.
    </p>
    <p class="text">
      Hautes Côtes reserves the right to decline accepting any traveler whose
      health or actions in Hautes Côtes sole judgment impede the operation of a
      trip or the welfare or enjoyment of fellow travelers.
    </p>
    <p class="text">
      In addition and without limitation, Hautes Côtes is not responsible for
      any injury, loss, or death, or damage to property in connection with the
      provision of any goods or services whether resulting from, without
      limitation to force majeure, weather, quarantines, illness, disease, acts
      of war, civil unrest, insurrection/revolt, animals, labor unrest including
      strikes, criminal or terrorist activities of any kind, overbooking or
      downgrading of services, food poisoning, mechanical or other failure of
      means of transportation or travel delays, travel advisories or warnings.
    </p>
    <p class="text">
      We strictly and expressly rely on each traveler to read, sign and submit
      our contract. The document must be read, signed and returned to us at
      least 30 days prior to the trip's departure. Our receipt of the completed
      and signed form will complete your reservation.
    </p>

    <h2 class="s-title">CANCELLING / SWITCHING TRIPS</h2>
    <p class="text">
      If you have to cancel your trip, we will make every effort to find a
      solution suitable for you. This may mean postponing the trip, transferring
      your deposit to another program or person, or some other configuration. In
      some cases, you may prefer a refund. Your cancellation and refund policy
      will be outlined in your contract as terms are dependent upon hotels and
      third-party providers.
    </p>

    <h2 class="s-title">USE OF PERSONAL DATA</h2>
    <p class="text">
      We value your personal data and will use it to the strict minimum, only
      when we have to. Travelers personal data collected by Hautes Côtes is used
      exclusively to provide the services of the experience booked, and to
      manage the passenger's security, safety, and wellbeing during the tour.
      Data will be shared with third party service providers, such as
      transportation, accommodation, who collaborate with Hautes Côtes on the
      delivery of the tour, only as needed. Data will also be shared with
      authorities if required by law, regulation, or court orders.
    </p>
    <p class="text logo-bottom-page">
      <img
        :src="`${$URL}assets/img/legal-logo-atoutfrance.webp`"
        alt="logo atout france"
      />
      Immatriculé article R.211-21 du code du tourisme
    </p>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "Legals",
  inject: ["$URL"],
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/mixins.scss";

.container {
  padding-top: 16rem;
  @include respond-to("md-down") {
    padding-top: 10rem;
  }
}
h1 {
  margin-bottom: 4rem;
  @include respond-to("md-down") {
    margin-bottom: 3rem;
  }
}
.text {
  max-width: 65%;
  font-size: 14px;
  margin: 2rem 0 2.5rem;
  line-height: 1.1em;
  @include respond-to("md-down") {
    max-width: 100%;
  }
}
.logo-bottom-page {
  align-items: flex-end;
  display: flex;
  gap: 20px;
  margin-top: 3.5rem;
}
br {
  line-height: 6rem;
}
ol {
  list-style: lower-roman;
  list-style-position: inside;
}
</style>
